var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"dialog--header\">\n    <h2 data-ui=\"title\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"scan_new_customer_card",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":69}}}))
    + "</h2>\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\n</div>\n<div class=\"dialog--content\">\n    <div class=\"input\">\n        <label for=\"code\" class=\"control-label\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"scan_customer_card",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":48},"end":{"line":7,"column":89}}}))
    + "</label>\n        <input type=\"text\" data-ui=\"code\" class=\"form-control code\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"scan_the_card",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":81},"end":{"line":8,"column":117}}}))
    + "\" disabled>\n    </div>\n    <button class=\"btn btn-success accept\" data-ui=\"accept\">\n        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"accept",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":37}}}))
    + "\n    </button>\n    <div data-region=\"keypad\" class=\"keypad\"></div>\n</div>";
},"useData":true});