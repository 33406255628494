var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-flex row-flex__10\">\n    <label for=\"date\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"date",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":22},"end":{"line":2,"column":49}}}))
    + "</label>\n    <button class=\"btn designer-box rounded btn-default themed-background-light\" data-action=\"date\">\n        "
    + alias2(((helper = (helper = lookupProperty(helpers,"date") || (depth0 != null ? lookupProperty(depth0,"date") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"date","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":16}}}) : helper)))
    + "\n    </button>\n    <label for=\"time\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"time",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":22},"end":{"line":6,"column":49}}}))
    + "</label>\n    <input type=\"text\" data-type=\"number\" data-ui=\"time\" class=\"designer-box time-input rounded themed-background-light\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"time") || (depth0 != null ? lookupProperty(depth0,"time") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"time","hash":{},"data":data,"loc":{"start":{"line":7,"column":128},"end":{"line":7,"column":136}}}) : helper)))
    + "\">\n</div>";
},"useData":true});