var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"dialog--header\">\n    <h2 data-ui=\"title\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"send_invoice",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":59}}}))
    + "</h2>\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\n</div>\n\n<div class=\"dialog--content\">\n    <div class=\"form-group\">\n        <label for=\"InputEmail\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"email_address",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":32},"end":{"line":8,"column":68}}}))
    + "</label>\n        <input type=\"email\" data-ui=\"input\" data-type=\"email\" class=\"form-control input-lg\" id=\"InputEmail\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"enter_email",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":121},"end":{"line":9,"column":155}}}))
    + "\">\n    </div>\n</div>\n<div class=\"dialog--footer two-button-container\">\n    <button data-action=\"submit\" class=\"btn btn-lg btn-success\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"send",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":64},"end":{"line":13,"column":91}}}))
    + "</button>\n    <button data-action=\"clear\" class=\"btn btn-lg btn-warning\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"clear",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":63},"end":{"line":14,"column":91}}}))
    + "</button>\n</div>\n";
},"useData":true});