var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <th>&nbsp;</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-vcenter table-striped\">\n    <thead>\n    <tr>\n        <th class=\"text-left\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"product_description",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":30},"end":{"line":4,"column":72}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"useStock") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "        <th class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"price",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":31},"end":{"line":8,"column":59}}}))
    + "</th>\n        <th></th>\n    </tr>\n    </thead>\n    <tbody></tbody>\n    <tfoot>\n        <tr>\n            <td colspan=\"3\">\n                <button class=\"btn btn-default btn-block\" data-action=\"more\" data-ui=\"more\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"load_more",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":92},"end":{"line":16,"column":124}}}))
    + "</button>\n            </td>\n        </tr>\n    </tfoot>\n</table>\n";
},"useData":true});