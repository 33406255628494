var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                    <button class=\"btn btn-primary btn-regular pull-right\" data-action=\"save-and-select\">\n                        <i class=\"fas fa-user-plus\"></i> "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"add_new_customer_and_select",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":57},"end":{"line":17,"column":107}}}))
    + "\n                    </button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                    <button class=\"btn btn-primary btn-regular pull-right\" data-action=\"save-and-select\" style=\"margin-right: 15px\">\n                        <i class=\"fas fa-user-plus\" style=\"margin-right: 5px;\"></i> "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"add_new_company_and_select",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":84},"end":{"line":21,"column":133}}}))
    + "\n                    </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--content__create-customer\">\n    <div class=\"block full rounded\">\n\n        <h2>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"create_a_new_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":56}}}))
    + "</h2>\n        <hr>\n\n        <form onsubmit=\"return false;\" method=\"post\">\n"
    + ((stack1 = container.invokePartial(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/shop.cash-register-retail/templates/customers/formPartial.hbs"),depth0,{"name":"modules/shop.cash-register-retail/templates/customers/formPartial","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n            <br>\n            <div class=\"form-group form-actions\">\n                <button class=\"btn btn-default btn-regular\" data-action=\"back\">\n                    <i class=\"fas fa-arrow-left\"></i> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"cancel",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":54},"end":{"line":13,"column":83}}}))
    + "\n                </button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_private") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":23,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n\n        </form>\n\n    </div>\n\n    <div data-region=\"popup\" class=\"hidden\"></div></div>";
},"usePartial":true,"useData":true});