var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label for=\"duration\" class=\"title\">\n    "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"duration_in_minutes",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":46}}}))
    + "\n</label>\n\n<div class=\"input-group\">\n    <input type=\"number\" id=\"duration\" class=\"form-control\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"duration_in_minutes",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":73},"end":{"line":6,"column":115}}}))
    + "\" data-action=\"duration\" data-ui=\"duration\" value=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"extra") : depth0)) != null ? lookupProperty(stack1,"duration_in_minutes") : stack1), depth0))
    + "\">\n    <span class=\"input-group-btn\">\n        <button class=\"btn designer-box rounded\" data-action=\"save-duration\">\n            <i class=\"fas fa-save\"></i>\n        </button>\n    </span>\n</div>";
},"useData":true});