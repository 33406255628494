var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"createCustomer") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":13,"column":19}}})) != null ? stack1 : "")
    + "            <label class=\"control-label\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"select_a_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":41},"end":{"line":14,"column":81}}}))
    + " ("
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"optional",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":83},"end":{"line":14,"column":114}}}))
    + ")</label>\n            <div data-region=\"customer\" class=\"inputs__customer\"></div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                <div class=\"row\">\n                    <div class=\"col-xs-6\">\n                        <button class=\"btn btn-primary btn-block form-button\" data-action=\"new-customer\" data-private=\"true\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"add_new_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":125},"end":{"line":7,"column":164}}}))
    + "</button>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <button class=\"btn btn-primary btn-block form-button\" data-action=\"new-customer\" data-private=\"false\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"add_new_company",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":126},"end":{"line":10,"column":164}}}))
    + "</button>\n                    </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--content\">\n    <div class=\"inputs\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"attachCustomer") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "\n        <label class=\"control-label\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"balance",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":37},"end":{"line":18,"column":67}}}))
    + "</label>\n        <div data-region=\"balance\" class=\"inputs__balance\"></div>\n\n        <label class=\"control-label\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"scan_card",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":37},"end":{"line":21,"column":69}}}))
    + "</label>\n        <div data-region=\"code\" class=\"inputs__code\"></div>\n    </div>\n    <button class=\"btn btn-success accept\" data-action=\"save\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"accept",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":62},"end":{"line":24,"column":91}}}))
    + "</button>\n    <div data-region=\"keypad\" class=\"keypad-container\"></div>\n</div>";
},"useData":true});