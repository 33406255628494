var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row mt-3\">\n                <div class=\"col-md-12\">\n                    <strong>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"examples",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":54,"column":28},"end":{"line":54,"column":61}}}))
    + "</strong>:\n                    <br>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"examples") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":20},"end":{"line":65,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"row\">\n                            <div class=\"col-md-6\">\n                                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"{points}_points",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":59,"column":32},"end":{"line":59,"column":73}}}))
    + "\n                            </div>\n                            <div class=\"col-md-6\">\n                                "
    + alias2(((helper = (helper = lookupProperty(helpers,"currencyValue") || (depth0 != null ? lookupProperty(depth0,"currencyValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"currencyValue","hash":{},"data":data,"loc":{"start":{"line":62,"column":32},"end":{"line":62,"column":51}}}) : helper)))
    + "\n                            </div>\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\n    <h2>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"use_loyalty_points",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":49}}}))
    + "</h2>\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\n</div>\n<div class=\"dialog--content\">\n    <div class=\"loyalty-input\">\n        <div class=\"row\">\n            <div class=\"col-md-8\">\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"loyalty_points_balance",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":63}}}))
    + ":\n            </div>\n            <div class=\"col-md-4\">\n                "
    + alias2(((helper = (helper = lookupProperty(helpers,"pointsBalance") || (depth0 != null ? lookupProperty(depth0,"pointsBalance") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"pointsBalance","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":35}}}) : helper)))
    + "\n            </div>\n        </div>\n        <div class=\"row mt-3\">\n            <div class=\"col-md-8\">\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"current_value",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":54}}}))
    + ":\n            </div>\n            <div class=\"col-md-4\">\n                "
    + alias2(((helper = (helper = lookupProperty(helpers,"currentValue") || (depth0 != null ? lookupProperty(depth0,"currentValue") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"currentValue","hash":{},"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":20,"column":34}}}) : helper)))
    + "\n            </div>\n        </div>\n        <div class=\"row mt-3\">\n            <div class=\"col-md-8\">\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"exchange_value",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":55}}}))
    + ":\n            </div>\n            <div class=\"col-md-4\">\n                <div data-region=\"input\" class=\"input\"></div>\n            </div>\n        </div>\n        <div data-region=\"balance\"></div>\n    </div>\n\n    <div data-region=\"details\" class=\"details full-child-height\">\n        <div class=\"row\">\n            <div class=\"col-md-12\">\n                <strong>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"current_exchange_rate",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":37,"column":70}}}))
    + "</strong>:\n                <br>\n                "
    + alias2(((helper = (helper = lookupProperty(helpers,"currentExchangeRate") || (depth0 != null ? lookupProperty(depth0,"currentExchangeRate") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"currentExchangeRate","hash":{},"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":39,"column":41}}}) : helper)))
    + "\n            </div>\n        </div>\n\n        <div class=\"row mt-3\">\n            <div class=\"col-md-12\">\n                <strong>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"rules",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":45,"column":24},"end":{"line":45,"column":54}}}))
    + "</strong>:\n                <br>\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/markdown/templates/helpers/render.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"usageExplanation") : depth0),{"name":"markdown$render","hash":{},"data":data,"loc":{"start":{"line":47,"column":16},"end":{"line":47,"column":54}}}))
    + "\n            </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasExamples") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":8},"end":{"line":68,"column":15}}})) != null ? stack1 : "")
    + "\n    </div>\n    <div data-region=\"keys\" class=\"keys full-child-height\"></div>\n</div>\n<div class=\"dialog--footer\">\n    <button class=\"btn btn-success btn-regular\" data-action=\"apply\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"confirm",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":74,"column":68},"end":{"line":74,"column":98}}}))
    + "</button>\n</div>";
},"useData":true});