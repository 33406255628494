var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"dialog--content\">\n    <div class=\"inputs\">\n        <div class=\"row\">\n            <div class=\"col-xs-6\">\n                <button class=\"btn btn-primary btn-block form-button\" data-action=\"new-customer\" data-private=\"true\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"add_new_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":117},"end":{"line":5,"column":156}}}))
    + "</button>\n            </div>\n            <div class=\"col-xs-6\">\n                <button class=\"btn btn-primary btn-block form-button\" data-action=\"new-customer\" data-private=\"false\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"add_new_company",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":118},"end":{"line":8,"column":156}}}))
    + "</button>\n            </div>\n        </div>\n        <label class=\"control-label\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"select_a_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":37},"end":{"line":11,"column":77}}}))
    + "</label>\n        <div data-region=\"customer\" class=\"inputs__customer\"></div>\n\n        <label class=\"control-label\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"balance",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":37},"end":{"line":14,"column":67}}}))
    + "</label>\n        <div data-region=\"balance\" class=\"inputs__balance\"></div>\n\n        <label class=\"control-label\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"scan_customer_card",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":37},"end":{"line":17,"column":78}}}))
    + "</label>\n        <div data-region=\"code\" class=\"inputs__code\"></div>\n    </div>\n    <button class=\"btn btn-success accept\" data-action=\"save\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"accept",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":62},"end":{"line":20,"column":91}}}))
    + "</button>\n    <div data-region=\"keypad\" class=\"keypad-container\"></div>\n</div>";
},"useData":true});