var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "\n        "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"add_points",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":41}}}))
    + ":\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"useremove_points",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":47}}}))
    + ":\n    ";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"col-md-12 alert-danger alert\">\n        "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"points_will_be_deducted_instantly_after_confirmation_if_the_order_is_abandoned_you_need_to_add_the_points_back_manually",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":16,"column":150}}}))
    + "\n    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"add_points",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":22,"column":49}}}))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"useremove_points",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":24,"column":55}}}))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button data-action=\"operationType\"\n                        class=\"btn btn-default btn-regular\"\n                        value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":45,"column":31},"end":{"line":45,"column":37}}}) : helper)))
    + "\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":46,"column":20},"end":{"line":46,"column":31}}}) : helper)))
    + "\n                </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\n    <h2 data-ui=\"title\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"addPoints") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + " "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/fullRelationName.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"customer") : depth0),{"name":"common$fullRelationName","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":48}}}))
    + "</h2>\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\n</div>\n\n<div class=\"dialog--content\">\n<div data-ui=\"message\"></div>\n\n<form onsubmit=\"return false;\" class=\"form-bordered\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inCheckout") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":0},"end":{"line":18,"column":7}}})) != null ? stack1 : "")
    + "    <div class=\"form-group row\">\n        <label for=\"positive_point_change\" class=\"col-md-2\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"addPoints") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":25,"column":19}}})) != null ? stack1 : "")
    + "        </label>\n        <div class=\"col-md-10\">\n            <input type=\"number\" class=\"form-control\" min=\"1\"  step=\"1\"  name=\"positive_point_change\" id=\"positive_point_change\">\n            <div class=\"help-block-static\" data-ui=\"point-calculation\"></div>\n        </div>\n    </div>\n\n    <div class=\"form-group row\">\n        <label for=\"value\" class=\"col-md-2\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"comment",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":34,"column":44},"end":{"line":34,"column":74}}}))
    + "</label>\n        <div class=\"col-md-10\">\n            <textarea id=\"comment\" name=\"comment\" rows=\"3\" class=\"form-control\"  placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"comment",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":36,"column":94},"end":{"line":36,"column":124}}}))
    + "\"></textarea>\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label for=\"value\" class=\"col-md-2\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"type",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":40,"column":44},"end":{"line":40,"column":71}}}))
    + "</label>\n        <div class=\"col-md-10\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"types") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":12},"end":{"line":48,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</form>\n\n</div>\n\n<div class=\"dialog--footer full-child-height\">\n    <button class=\"btn btn-default btn-regular\" data-action=\"close\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"cancel",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":56,"column":68},"end":{"line":56,"column":97}}}))
    + "</button>\n    <button class=\"btn btn-success btn-regular\" data-action=\"confirm\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"save",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":57,"column":70},"end":{"line":57,"column":97}}}))
    + "</button>\n</div>\n";
},"useData":true});