var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCancelled") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":16,"column":19}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                <span class=\"text text-warning\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"packing_slip_printing_cancelled",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":48},"end":{"line":9,"column":102}}}))
    + " "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"{orders_printed_count}{orders_total_count}_printed",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":103},"end":{"line":9,"column":179}}}))
    + "</span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isError") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":15,"column":23}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                    <span class=\"text text-danger\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"packing_slip_printing_failed",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":51},"end":{"line":12,"column":102}}}))
    + " "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"{orders_printed_count}{orders_total_count}_printed",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":103},"end":{"line":12,"column":179}}}))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                    <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"packing_slip_printing_successful",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":26},"end":{"line":14,"column":81}}}))
    + "</span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ordersToPrintTotal") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":22,"column":19}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "                <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"printing_{orders_total_count}_open_orders",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":19,"column":22},"end":{"line":19,"column":88}}}))
    + "</span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"initializing",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":22},"end":{"line":21,"column":57}}}))
    + "</span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "progress-bar-warning";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isError") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":26,"column":84},"end":{"line":26,"column":153}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    return "progress-bar-danger";
},"19":function(container,depth0,helpers,partials,data) {
    return "progress-bar-success";
},"21":function(container,depth0,helpers,partials,data) {
    return "progress-bar-striped active";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"ordersToPrintTotal") : depth0),{"name":"unless","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":37,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCancelled") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":45,"column":23}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isError") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":32,"column":20},"end":{"line":36,"column":27}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    return "                        <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"no_orders_were_printed",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":33,"column":30},"end":{"line":33,"column":75}}}))
    + "</span>\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "                        <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"skipped_all_orders_were_printed",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":35,"column":30},"end":{"line":35,"column":84}}}))
    + "</span>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"printPercentage") || (depth0 != null ? lookupProperty(depth0,"printPercentage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"printPercentage","hash":{},"data":data,"loc":{"start":{"line":40,"column":26},"end":{"line":40,"column":47}}}) : helper)))
    + "%</span>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isError") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":20},"end":{"line":44,"column":27}}})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"printPercentage") || (depth0 != null ? lookupProperty(depth0,"printPercentage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"printPercentage","hash":{},"data":data,"loc":{"start":{"line":43,"column":30},"end":{"line":43,"column":51}}}) : helper)))
    + "%</span>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"printPercentage") || (depth0 != null ? lookupProperty(depth0,"printPercentage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"printPercentage","hash":{},"data":data,"loc":{"start":{"line":47,"column":22},"end":{"line":47,"column":43}}}) : helper)))
    + "%</span>\n";
},"36":function(container,depth0,helpers,partials,data) {
    return "            <button data-action=\"close\" class=\"btn btn-regular btn-default\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"done",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":55,"column":76},"end":{"line":55,"column":103}}}))
    + "</button>\n";
},"38":function(container,depth0,helpers,partials,data) {
    return "            <button data-ui=\"stop-print\" class=\"btn btn-regular btn-danger\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"cancel",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":57,"column":76},"end":{"line":57,"column":105}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\n    <h2 data-ui=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":35}}}) : helper)))
    + "</h2>\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\n</div>\n<div class=\"dialog--content\">\n    <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"printDone") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"progress\">\n            <div data-ui=\"progressbar-new\"\n                 class=\"progress-bar "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCancelled") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":26,"column":37},"end":{"line":26,"column":160}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"printDone") : depth0),{"name":"unless","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":161},"end":{"line":26,"column":220}}})) != null ? stack1 : "")
    + "\"\n                 role=\"progressbar\"\n                 style=\"width: "
    + alias4(((helper = (helper = lookupProperty(helpers,"printPercentage") || (depth0 != null ? lookupProperty(depth0,"printPercentage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"printPercentage","hash":{},"data":data,"loc":{"start":{"line":28,"column":31},"end":{"line":28,"column":52}}}) : helper)))
    + "%\">\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"printDone") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":48,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n<div class=\"dialog--footer\">\n    <div class=\"one-button-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"printDone") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(38, data, 0),"data":data,"loc":{"start":{"line":54,"column":8},"end":{"line":58,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});