var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row mt-3\">\n    <div class=\"col-md-8\">\n        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"balance_remaining_after_exchange",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":65}}}))
    + ":\n    </div>\n    <div class=\"col-md-4\">\n        "
    + alias2(((helper = (helper = lookupProperty(helpers,"remainingBalance") || (depth0 != null ? lookupProperty(depth0,"remainingBalance") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"remainingBalance","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":30}}}) : helper)))
    + "\n    </div>\n</div>";
},"useData":true});